<template>
  <next-step
    :img-src="$getAsset('/assets/search/ancestors-search-trimmed.png')"
    :step-number="2"
    title="Search for ancestors in our records database"
  >
    <div slot="description">
      Browse our collections of family tree books, tombstones, and historical records of Overseas Chinese.
    </div>
    <router-link slot="action" :to="ancestorsSearch">Search for {{ pinyin }} ancestors</router-link>
  </next-step>
</template>
<script>
import NextStep from '@/components/modules/getStarted/NextStep';

export default {
  name: 'next-step-ancestors-search',
  components: {NextStep},
  props: {
    ancestorsSearch: {type: Object},
    pinyin: {type: String},
  },
};
</script>
<style lang="scss" scoped>
hr {
  margin: 70px 0 40px;
}
</style>
