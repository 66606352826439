<template>
  <next-step
    class="next-step-discord"
    :img-src="$getAsset('/assets/services/who_we_are.jpg')"
    :step-number="4"
    title="Join our discord community"
  >
    <div slot="description">
      Get inspired, share resources and chat with other users on My China Roots researching their ancestry.
    </div>
    <discord-join-button slot="action" @click.native="trackDiscordClick"></discord-join-button>
  </next-step>
</template>

<script>
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';
import {getRoutePageName} from '@common/utils/analytics/utils.analytics';

import DiscordJoinButton from '@/base/elements/buttons/DiscordJoinButton';

import NextStep from '@/components/modules/getStarted/NextStep';

export default {
  methods: {
    trackDiscordClick() {
      AnalyticsMainHandler.trackClickDiscordLink('body', getRoutePageName(this.$route));
    },
  },
  components: {DiscordJoinButton, NextStep},
  name: 'NextStepDiscord',
};
</script>

<style scoped></style>
