<template>
  <div class="next-step">
    <div class="image-wrapper">
      <img :src="imgSrc" class="image" />
    </div>
    <div class="step-overview">
      <h5 class="step-number">{{ stepNumber }}.</h5>
      <div class="step-description">
        <h5>{{ title }}</h5>
        <div class="description-wrapper">
          <slot name="description"></slot>
        </div>
        <slot name="action"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imgSrc: String,
    stepNumber: Number,
    title: String,
  },
};
</script>

<style lang="scss" scoped>
.next-step {
  display: flex;
  justify-content: space-between;

  .image-wrapper {
    width: 300px;
    flex-shrink: 0;
    margin-right: 30px;
    display: flex;
    justify-content: center;
    .image {
      max-width: 230px;
    }
  }
  .step-overview {
    display: flex;

    .step-description {
      display: flex;
      flex-direction: column;
      margin-left: 20px;

      .description-wrapper {
        margin: 10px 0;
      }
    }
  }
}
@media only screen and (max-width: $breakpoint-mobile) {
  .next-step {
    flex-direction: column-reverse;

    .image-wrapper {
      padding: 20px 30px 0;
      margin-right: 0;
      width: auto;

      .image {
        max-width: 100%;
      }
    }
  }
}
</style>
