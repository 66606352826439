<template>
  <next-step
    class="family-tree-step"
    :img-src="$getAsset('/assets/services/family-tree-software-cropped.png')"
    :step-number="1"
    :title="title"
  >
    <div slot="description">
      Every search begins with collecting names, dates, and places relevant to your ancestors.
    </div>
    <router-link slot="action" :to="familyTreeRoute">{{ actionText }}</router-link>
  </next-step>
</template>
<script>
import NextStep from '@/components/modules/getStarted/NextStep';

export default {
  name: 'next-step-family-tree',
  components: {NextStep},
  props: {
    familyTreeRoute: {},
    title: {type: String, default: 'Build your family tree'},
    actionText: {type: String, default: 'Start building my tree'},
  },
};
</script>
<style lang="scss" scoped>
hr {
  margin: 70px 0 40px;
}

.next-step.family-tree-step::v-deep .image-wrapper .image {
  max-width: 300px;
  @media only screen and (max-width: $breakpoint-mobile) {
    max-width: 100%;
  }
}
</style>
