import { render, staticRenderFns } from "./NextStep.vue?vue&type=template&id=6ff15c35&scoped=true&"
import script from "./NextStep.vue?vue&type=script&lang=js&"
export * from "./NextStep.vue?vue&type=script&lang=js&"
import style0 from "./NextStep.vue?vue&type=style&index=0&id=6ff15c35&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ff15c35",
  null
  
)

export default component.exports