<template>
  <next-step
    :img-src="$getAsset('/assets/guides/genealogy-101-preview.PNG')"
    :step-number="3"
    title="Read our tips to get started"
  >
    <div slot="description">
      Check out our quick PDF guide, explaining what records you might find about your ancestry, both in and out of
      China.
    </div>
    <a slot="action" :href="$getAsset('/assets/guides/MyChinaRoots-Genealogy-101.pdf')" target="_blank"
      >Download PDF Guide</a
    >
  </next-step>
</template>
<script>
import NextStep from '@/components/modules/getStarted/NextStep';

export default {
  name: 'next-step-guide',
  components: {NextStep},
};
</script>
<style lang="scss" scoped>
hr {
  margin: 70px 0 40px;
}
</style>
